<template>
  <div>

    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          :rules="isoLang === '' ? 'required' : ''"
          :name="`<b>${$t('Nome do imóvel para website').toLowerCase()}</b>`"
        >
          <b-form-group
            :label="$t('Nome do imóvel para website')"
          >
            <b-form-input
              v-if="view===false"
              :id="isoLang === '' ? 'nameWebsite' : `nameWebsite_${isoLang}`"
              v-model="descriptive.nameWebsite"
              placeholder=""
              autocomplete="off"
              maxlength="70"
              :class="isoLang === '' ? errors.length > 0 ? 'is-invalid' : null : ''"
            />
            <p
              v-else
              class="text-primary mb-0"
            >
              {{ getTxt(descriptive.nameWebsite) }}
            </p>
          </b-form-group>
        </validation-provider>

      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <validation-provider
          #default="{ errors }"
          :rules="isoLang === '' ? 'required' : ''"
          :name="`<b>${$t('Descritivo curto').toLowerCase()}</b>`"
        >
          <b-form-group
            :label="$t('Descritivo curto')"
          >
            <b-form-textarea
              v-if="view===false"
              :id="isoLang === '' ? 'shortDescriptive' : `shortDescriptive_${isoLang}`"
              v-model="descriptive.short"
              :state="typeof descriptive.short !== 'undefined' && descriptive.short !== null && descriptive.short !== '' ? descriptive.short.length <= 150 : null"
              placeholder=""
              rows="4"
              :class="isoLang === '' ? errors.length > 0 ? 'is-invalid' : null : ''"
            />
            <p
              v-else
              class="text-primary mb-0"
            >
              {{ getTxt(descriptive.short) }}
            </p>
          </b-form-group>
        </validation-provider>

      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Descritivo completo')"
        >
          <quill-editor
            v-if="view===false"
            :id="isoLang === '' ? 'completeDescriptive' : `completeDescriptive_${isoLang}`"
            v-model="descriptive.complete"
            :options="isoLang === '' ? { modules: { toolbar: '#toolbar' }, placeholder: '', theme: 'snow' } : { modules: { toolbar: `#toolbar_${isoLang}` }, placeholder: '', theme: 'snow' }"
          >
            <div
              :id="isoLang === '' ? 'toolbar' : `toolbar_${isoLang}`"
              slot="toolbar"
            >
              <span class="ql-formats">
                <!-- Add a bold button -->
                <button
                  v-b-tooltip.hover.top="$t('Negrito')"
                  class="ql-bold"
                />
                <!-- Add a italic button -->
                <button
                  v-b-tooltip.hover.top="$t('Itálico')"
                  class="ql-italic"
                />
                <!-- Add a underline button -->
                <button
                  v-b-tooltip.hover.top="$t('Sublinhado')"
                  class="ql-underline"
                />
              </span>

              <span class="ql-formats">
                <!-- Add a list-ordered button -->
                <button
                  v-b-tooltip.hover.top="$t('Lista')"
                  class="ql-list"
                  value="ordered"
                />
                <!-- Add a list-bullet button -->
                <button
                  v-b-tooltip.hover.top="$t('Lista numerada')"
                  class="ql-list"
                  value="bullet"
                />
              </span>
              <span class="ql-formats">
                <!-- Add a remove Indent button -->
                <button
                  v-b-tooltip.hover.top="$t('Remover indentação')"
                  class="ql-indent"
                  value="-1"
                />
                <!-- Add a Indent button -->
                <button
                  v-b-tooltip.hover.top="$t('Indentar')"
                  class="ql-indent"
                  value="+1"
                />
              </span>
              <span class="ql-formats">
                <!-- Add a link button -->
                <button
                  v-b-tooltip.hover.top="$t('Inserir link')"
                  class="ql-link"
                />
              </span>
              <span class="ql-formats">
                <!-- Add a remove formatting button -->
                <button
                  v-b-tooltip.hover.top="$t('Remover formatação')"
                  class="ql-clean"
                />
              </span>

            </div>
          </quill-editor>

          <!-- eslint-disable vue/no-v-html -->
          <p
            v-else
            class="text-primary mb-0"
            v-html="getTxt(descriptive.complete)"
          />

          <p
            v-if="descriptive.script !== null && descriptive.script !== ''"
            :id="isoLang === '' ? 'scriptDescriptive' : `scriptDescriptive_${isoLang}`"
            class="text-primary mb-0 mt-1"
            v-html="getTxt(descriptive.complete)"
          />
          <!--eslint-enable-->
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Palavras-chave')"
        >
          <b-form-tags
            v-if="view===false"
            v-model="descriptive.keyWords"
            :input-id="isoLang === '' ? 'keyWords' : `keyWords_${isoLang}`"
            :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
            tag-variant="primary"
            tag-pills
            separator=","
            placeholder=""
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderArrayToTxt(descriptive.keyWords) }}
          </p>
        </b-form-group>
        <b-form-text
          v-if="view===false"
          id="tags-remove-on-delete-help"
        >
          {{ $t('Separe as palavras com virgulas') }}
        </b-form-text>
      </b-col>
    </b-row>

    <component :is="`style`">
      .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
      content: '{{ $t('Guardar') }}'!important;
      }

      .ql-snow .ql-tooltip a.ql-action::after {
      content: '{{ $t('Editar') }}'!important;
      }

      .ql-snow .ql-tooltip a.ql-remove::before{
      content: '{{ $t('Eliminar') }}'!important;
      }

      .ql-snow .ql-tooltip[data-mode=link]::before {
      content: '{{ $t('Inserir link') }}:'!important;
      }

      .ql-snow .ql-tooltip::before{
      content: '{{ $t('Link') }}:'!important;
      }
    </component>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea, VBTooltip, BFormTags, BFormText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from '@/views/modules/components/editor-html'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormTags,
    BFormText,
    quillEditor,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    isoLang: {
      type: String,
      default: () => 'pt',
    },
    view: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    descriptive: {
      nameWebsite: '',
      short: '',
      complete: '',
      script: '',
      keyWords: [],
    },
  }),
  methods: {
    async loadDataForm(payload) {
      try {
        this.descriptive.nameWebsite = payload.nameWebsite || null
        this.descriptive.short = payload.short || null
        this.descriptive.complete = payload.complete || null
        this.descriptive.script = payload.script || null
        this.descriptive.keyWords = payload.keyWords || null
      } catch (err) {
        //
      }
    },
    getTxt(value) {
      if ((value !== null) && (value !== undefined) && (value !== '')) {
        return value
      }
      return '-----'
    },
    renderArrayToTxt(value) {
      const aValue = value
      let txt = ''

      if ((aValue !== null) && (aValue !== undefined) && (aValue.length > 0)) {
        aValue.forEach(o => {
          txt += ((txt) ? ', ' : '') + o
        })
      }

      return txt || '-----'
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.ql-container,  .ql-container .ql-editor{
    min-height: 180px;
}
</style>

<style lang="scss" scoped>
.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

::v-deep .ql-snow .ql-tooltip {
  white-space:normal!important;
}
</style>
